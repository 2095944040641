<template>
    <div class="all-page">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back"  style="margin-right: 15px;" name="arrow-left" size="25"/>
                联系客服
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置:</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">联系客服</span>
            </div>
        </div>

        <div class="kf-view">
            <div class="common-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        QQ客服
                    </div>
                </div>
                <div class="kf-item-view" v-for="item in webConfig.qqkfList" :key="item.info">
                    <img src="../../assets/qq.png" class="kf-img">
                    <div class="kf-info">
                        <div>QQ:{{ item.info }}
                            <span @click="openQQKf(item.info)" class="copy-button">咨询</span></div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            {{ item.worktime }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="common-view" v-for="item in webConfig.wxkfList" :key="item.info">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        微信客服
                    </div>
                </div>
                <div class="kf-item-view">
                    <img src="../../assets/wx_b.png" class="kf-img">
                    <div class="kf-info">
                        <div @click="openWeixinKf(item.info)" style="user-select: none;cursor: pointer;color: #ee3a3a">点击立即咨询</div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            {{ item.worktime }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="common-view" v-for="item in webConfig.telkfList" :key="item.info">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        联系电话
                    </div>
                </div>
                <div class="kf-item-view">
                    <img src="../../assets/cellphone.png" class="kf-img">
                    <div class="kf-info">
                        <div>电话: {{ item.info }}<span @click="copy(item.info)" class="copy-button">复制</span></div>
                        <div style="color: #888888;margin-top: 10px;font-size: 14px;">
                            {{ item.worktime }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
<!--        <home-footer v-if="from !== 'android_box'" class="m-footer"></home-footer>-->
        <home-footer class="m-footer m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant'
    import notice from "../../api/notice";

    import homeNav from "@/components/home/HomeNav";
    import homeFooter from "@/components/home/HomeFooter";
    import gzhCodeView from "@/components/home/GzhCodeView";
    import {request} from "@/api/request";
    import config from "@vant/icons";
    import webConfig from "@/api/config";

    export default {
        name: "Kf",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {
                isSelfSigned: true,
                from: '',
                webConfig: {},
            }
        },
        mounted() {
            this.from = localStorage.getItem('from');
            this.webConfig = webConfig;
        },
        methods: {
            back() {
                this.$router.back();
            },
            openWeixinKf(url) {
                let weixinKfUrl = url;
                notice.openUrl(weixinKfUrl)
            },
            copy(v) {
                notice.copy(v)
                this.$toast("复制成功");
            },
            openQQKf(qq) {
                let qqUrl = 'mqq://im/chat?chat_type=wpa&uin=' + qq + '&version=1&src_type=web';
                notice.openUrl(qqUrl)
            }
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #f8f7f5;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .all-page::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }
    .kf-item-view {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .kf-view {
        width: 100%;
        max-width: 900px;
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .kf-info {
        color: #333333;
        margin-top: 30px;
        font-size: 20px;
        display: flex;
        flex-direction: column;
    }
    .copy-button {
        color: #ee3a3a;
        margin-left: 10px;
        font-size: 16px;
        display: none;
        user-select: none;
        cursor: pointer;
    }
    .common-view {
        width: 290px;
        height: 320px;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .top-view-name_pre {
        width: 5px;
        height: 20px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 80px;
        font-size: 25px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
    .m-title {
        display: none;
        font-size: 20px;
        color: #333333;
        width: 100%;
        height: 110px;
        text-align: left;
        align-items: flex-end;
        padding: 0 10px;
        position: fixed;
        z-index: 999;
        background-color: #f8f7f5;
        box-sizing: border-box;
    }
    .kf-img {
        width: 90px;
        height: 90px;
        margin-top: 10px;
        border-radius: 12px;
    }
    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .p-title {
            display: none;
        }
        .m-title {
            display: flex;
        }
        .kf-view {
            flex-direction: column;
            margin-top: 100px;
        }
        .common-view {
            width: 90%;
            margin-top: 10px;
            height: unset;
            margin-bottom: 10px;
        }
        .top-view-name_pre {
            width: 3px;
            height: 15px;
        }
        .copy-button {
            display: unset;
            font-size: 14px;
        }
        .kf-info {
            margin-top: 0;
            margin-left: 10px;
            height: 40px;
            justify-content: space-between;
            align-items: flex-start;
            font-size: 14px;
        }
        .kf-item-view {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 15px 10px;
        }
        .kf-img {
            width: 50px;
            height: 50px;
        }
        .top-view-head {
            height: 40px;
            font-size: 16px;
            padding-left: 15px;
        }
    }
</style>